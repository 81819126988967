#lottery-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .lottery-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .lottery-table-panel,
  .lottery-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .lottery-tab-panel,
  .mobile .lottery-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .supreme-card .header {
    min-height: 3em;
  }
  .supreme-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
  .lottery-note {
    text-align: center;
    font-style: italic;
  }
  .lottery-note1 {
    text-align: center;
    font-family: ui-rounded;
    font-size: 16px;
    line-height: 16px;
  }
}

#lottery-view .MuiGrid-spacing-xs-1{
  width: 100%;
  max-width: 833px;
}

.migrate-smama-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-smama-button.complete {
  color: #35ee66 !important;
}

.lottery-top-metrics {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  h5 {
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
  }
  .lottery-apy,
  .lottery-tvl,
  .lottery-index {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
  }
}

.mobile .lottery-top-metrics {
  .lottery-apy,
  .lottery-tvl,
  .lottery-index {
    align-items: flex-start;
  }
}

.mama-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.lottery-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}

.lottery-action-row {
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-bottom: 1rem;
}

.mobile .lottery-action-row {
  justify-content: center;
}

.lottery-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .lottery-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

.mama-input {
  max-width: 542px;
  width: 100%;
  margin: 5px !important;
}

.lottery-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
}

.mobile .lottery-button {
  width: 100% !important;
  margin: 0px !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
}

.staking-area {
  margin: 13px 10px 13px 10px;
}

.lottery-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.lottery-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.tablet .lottery-pool,
.mobile .lottery-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .mama-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .lottery-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.lottery-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}

.lottery-warmup-text {
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-top: 1rem;
}

.mobile .lottery-warmup-text {
  justify-content: center;
}

.lottery {
  display: grid;
  grid: 1fr max-content/1fr;
}
.sup-input{
  width: 100%;
}